.pageBackground {
    background-color: var(--background-color);
    max-width: 1280px;
    justify-content: center;
    margin: 0 auto;
    padding-top:10px;
    margin-bottom: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.selector {
    max-width: 1100px;
    justify-content: center;
    margin: 0 auto;
    padding-top:10px;
}

.tableTitleC {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: var(--font-family-primary);
    padding-top:10px;
    padding-bottom:10px;
}

.eventsDropDown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-family: var(--font-family-primary);
}

.teamBox {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px;
    max-height:30px;
    gap: 10px;
    background: var(--team-color);
    border-radius: 3px;
    font-weight: normal;
    color: var(--secondary-color);
    font-family: var(--font-family-primary);
  }

  .progBox {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    max-height: 30px;
    gap: 10px;
    border-radius: 3px;
    font-weight: normal;
    color: var(--secondary-color);
    font-family: var(--font-family-primary);
    max-width: 100%;
    flex-wrap: wrap;
  }

.teamName {
    padding: 5px;
}

.rankBox {
    max-width: 20px;
    font-variant: bold;
}

@media screen and (min-width: 1024px) {
    .selector {
        display: flex;
        justify-content: space-between;
    }
    .pageSelector {
        display: flex;
        justify-content: flex-end;
        text-align: right;
    }
    
    .pageDisplay {
        padding-top: 8px;
    }
    
    .tableTitle {
        display: flex;
        justify-content: flex-start;
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        font-family: var(--font-family-primary);
    }

    .leftDisplay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 20px;
    }
    
    .title {
        font-size: 2rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        text-align: left;
        font-weight: bold;
    }

    .leftOngoing {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: -25.3rem;
        margin-bottom: 20px;
    }
    
    .dropdownDisplay {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .eventDisplay {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
    }
}

@media screen and (max-width: 1023px){
    .dropdownDisplay {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
      
    .title {
        font-size: 2rem;
        margin-bottom: 0rem;
        margin-top: 0rem;
        text-align: center;
        font-weight: bold;
    }

    .eventOngoingAdjust {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    
    .pageSelector {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .pageDisplay {
        padding-top: 8px;
    }
    
    .tableTitle {
        padding-top: 20px;
        display: flex;
        font-size: 20px;
        font-weight: bold;
        font-family: var(--font-family-primary);
        justify-content: center;
    }
}