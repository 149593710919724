@media screen and (min-width: 1024px) {
  .filter {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-inline: 30px;
    filter: drop-shadow(1px 0px 5px var(--shadow-color));
  }
}

.query {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  background: var(--primary-color);
  border-radius: 5px 0px 0px 5px;
  order: 0;
  flex-grow: 0;
  width: auto;
  height: 34px;
  color: var(--secondary-color);
  font-family: var(--font-family-primary);
}

.program {
  width: 46px;
  height: 14px;
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary-color);
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* Search Filter */
.search-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 10px;
  width: flex;
  height: 34px;
  background: var(--secondary-color);
  border-bottom: 1px solid var(--accent-color);
  border-radius: 0px 5px 5px 0px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: var(--font-family-primary);
}

.search-filter select {
  background-color: var(--secondary-color);
  font-family: var(--font-family-primary);
}

.search-filter input {
  background-color: var(--secondary-color);
  font-family: var(--font-family-primary);
}

/* Select fruit */
.select-fruit {
  width: 21px;
  height: 14px;
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-color);
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* For buttons */
.buttonText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--accent-color);
  font-family: var(--font-family-primary);
}

@media screen and (max-width: 1023px) {
  .filter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-inline: 30px;
    filter: drop-shadow(1px 0px 5px var(--shadow-color));
    padding: 5px 10px;
  }
}
