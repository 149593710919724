:root {
    --primary-color: #28191D;
    --secondary-color: #E5D5D1;
    --orange-color: #bd4426;
    --white-color: #E5DCD7;
    --banner-color: #84202A;
    --accent-color: #84202A;
    --team-color: #84202A;
    --text-color: #28191D;
    --background-color: #ebebeb;
    --shadow-color: rgba(114, 20, 29, 0.25);
    --highlight-color: rgb(152, 216, 250);
    --subtitle-color: rgb(227, 153, 153);
    --transition-duration: 0.2s;
    --font-family-primary: 'Proxima Nova', sans-serif;
}

.colorPrimary {
    background: var(--primary-color);
}

.colorSecondary {
    background: var(--secondary-color);
}

.colorNavBar {
    background: var(--banner-color);
}