.match{
    max-width: 800px;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 20px;
    background-color: var(--white-color);
    border-radius: 10px;
    border: 1px solid #777777;
}

.matchesTitle {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding: 10px 10px;
}

.matchContainer {
    display: flex;
    max-height: 200px;
    margin-bottom: 10px;
    background-color: white;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    border: 1px solid #777777;
}

.highlight-winner {
    background: linear-gradient(to right, rgba(144, 238, 144, 0.5), white, white, rgba(144, 238, 144, 0.5));
}

.highlight-loser {
    background: linear-gradient(to right, rgba(255, 182, 193, 0.5), white, white, rgba(255, 182, 193, 0.5));
}

.matchInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
}

.matchName {
    width: 200px;
    text-align: left;
}

.matchLocation {
    width: 200px;
    text-align: right;
}

.matchTeamDisplay {
    font-size: 16px;
    font-weight: 700;
    width: 100px;
}

@media screen and (max-width: 500px) {
    .match{
        font-size: 8px;
    }
    .matchTeamDisplay {
        font-size: 8px;
        font-weight: 700;
        width: 50px;
    }
    .matchContainer {
        margin-bottom: 5px;
    }
}