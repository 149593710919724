  .team-info-layout {
      background-color: var(--banner-color);
  }
  
  .team-container {
      display: flex;
      justify-content: center;
      background-color: var(--banner-color);
  }
  
  .transition {
      transition-duration: var(--transition-duration);
  }
  
  .team-button:hover {
      transform: scale(1.1);
  }
  
  .team-button:focus {
      outline: none;
  }
  
  .team-button.active {
      border-bottom: 5px solid var(--primary-color);
  }
  
  .team-info-display {
      background-color: var(--background-color);
      max-width: 1200px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      margin: 0 auto;
      margin-bottom: 40px;
      display: block;
  }
  
  .team-profile-subtitle {
      font-weight: bold;
      font-size: 20px;
      text-align: center;
  }
  
  .team-profile-info {
      display: flex;
      justify-content: center;
  }
  
  .team-profile-row {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 250px;
  }
  
  .team-profile-label {
      font-weight: bold;
      font-size: 15px;
  }
  
  .team-profile-rank-value {
      font-size: 40px;
      color: var(--primary-color);
      text-align: center;
  }
  
  .team-profile-rank-label {
      color: var(--banner-color);
      font-weight: bold;
      font-size: 15px;
      text-align: center;
  }
  
  .smooth-switch {
      transition: all 0.3s ease;
  }
  
  .event-profile-info {
      display: flex;
      justify-content: center;
  }
  
  .event-profile-row {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 250px;
  }
  
  .event-profile-label {
      color: var(--banner-color);
      font-weight: bold;
      font-size: 15px;
      text-align: center;
  }
  
  .event-profile-subtitle {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      padding-top: 20px;
  }
  
  .champion {
    position: relative;
    font-family: var(--font-family-primary);
    text-transform: uppercase;
    font-weight: bold;
    color: #FF5733; /* Orange color */
    letter-spacing: 1px;
    font-size: 50px;
    line-height: 1.2;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    }



  .event-profile-value {
      font-size: 30px;
      color: var(--primary-color);
      text-align: center;
  }
  
  @media screen and (min-width: 1400px) {
      .team-info-layout {
          background-color: var(--banner-color);
      }
  
      .team-info-background {
          margin-left: 100px;
          margin-right: 100px;
      }
  
      .title-team-info {
          text-align: left;
          padding-top: 10px;
          color: var(--white-color);
          font-weight: bold;
          font-size: 30px;
          position: relative;
          left: calc((100vw - 1300px) / 2);
      }
  
      .subtitle-team-info {
          text-align: left;
          color: var(--subtitle-color);
          font-weight: bold;
          font-size: 15px;
          position: relative;
          left: calc((100vw - 1300px) / 2);
      }

      .team-button {
          padding-left: 25px;
          padding-right: 25px;
          padding-bottom: 8px;
          position: relative;
          top: -20px;
          margin-bottom: -20px;
          color: var(--white-color);
          cursor: pointer;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
      }
  }
  
  @media screen and (max-width: 666px) {
      .title-team-info {
          text-align: center;
          padding-top: 10px;
          color: var(--white-color);
          font-weight: bold;
          font-size: 30px;
      }
  
      .subtitle-team-info {
          text-align: center;
          color: var(--subtitle-color);
          font-weight: bold;
          font-size: 15px;
      }
  
      .team-button {
          font-size: 15px;
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;
          position: relative;
          color: var(--white-color);
          cursor: pointer;
          top: 10px;
          margin-bottom: 10px;
      }
  }
  
  @media screen and (max-width: 1399px) and (min-width: 667px) {
      .title-team-info {
          text-align: center;
          padding-top: 10px;
          color: var(--white-color);
          font-weight: bold;
          font-size: 30px;
      }
  
      .subtitle-team-info {
          text-align: center;
          color: var(--subtitle-color);
          font-weight: bold;
          font-size: 15px;
      }
  
      .team-button {
          font-size: 18px;
          text-align: center;
          padding-left: 20px;
          padding-right: 10px;
          position: relative;
          color: var(--white-color);
          cursor: pointer;
          top: 10px;
          margin-bottom: 10px;
      }
  }
  